import React, {useRef, useEffect} from 'react';
import gsap from 'gsap'

const Textarea = ({field, form:{touched, errors}, ...props}) => {
  let errorEl = useRef(null)

  useEffect(() => {
    (errors[field.name] && touched[field.name]) && gsap.from(errorEl, {duration: 1.2, y: -20, opacity: 0, ease: "back"})

  },)

  return (
  <div className="textarea-wrapper">   
    <textarea className="styled-textarea" {...field} {...props}>{props.children}</textarea>
    <div className="textarea-error" ref={el => errorEl = el} >{errors[field.name]}</div>
  </div>
  )
};

export default Textarea;