import React, {useState, useEffect, useContext} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import 'yup-phone'
import Input from '../ui/form/input'
import { gsap } from 'gsap/'
import { FirebaseContext } from '../firebase'
import Textarea from "../ui/form/textarea"

const BaccSchema = Yup.object().shape({
  nom: Yup.string().required("Veuillez saisir votre nom"),
  prenom: Yup.string().required("Veuillez saisir votre prenom"),
  email: Yup.string().email("Cette email n'est pas valide").required("Veuillez saisir votre email"),
  phone: Yup.string().phone("${path} n'est pas valide"),
  city: Yup.string(),
  interest: Yup.string(),
})

const Terminal = () => {

  const [sent, setSent] = useState(false)

  const isSent = () =>{
    setSent(true)
  }

  const now = new Date()

  const fb = useContext(FirebaseContext)

  const sendForm = (values) => {
    
    const {nom, prenom, email, phone, city, interest } = values

    fb.db.collection("africastudent").add({
        firstname: prenom,
        lastname: nom,
        email: email,
        phone: phone,
        city: city,
        interest: interest,
        createdAt: new Date(now)
    })

    const message = "Bientôt, nous vous communiquerons les informations nécessaires à votre participation à la semaine de cours gratuite."

    const subject = "Merci de votre intérêt à ce programme "

    fetch('https://us-central1-elearning-9cb37.cloudfunctions.net/sendEmail', {
      method: 'POST',
      body: JSON.stringify({email, prenom, subject, message})
    }).then(res => {
      return res.json();
    })
    
  }

  useEffect(() => {
    !sent && gsap.to(formRef.children, {opacity: 1, duration: .8, y: 20, ease: "back", stagger: .1})
    sent && gsap.from(".form-sent", {opacity: 0, duration: .8, y: 20, ease: "back"})
  },)


  let formRef = null

  return(
    <Layout>
      <SEO title="Cours particuliers en Math et Physique" />
      <div className="page-container services">
        <h2 className="page-header color-0 center">Cours de mise à niveau</h2>
        <section id="app-dev">
          <div className="container2">
            <p className="section-body2 color-1">
              Bienvenue sur le site BizHighTech
              <span role="img" aria-labelledby="smile">😃</span>. Si vous êtes là parce que vous êtes intéressé &agrave; augmenter vos connaissances en math. Nous proposons 2 séances par semaine. Nous vous offrons la premi&egrave;re semaine de cours <strong>GRATUITEMENT</strong>!!! Alors qu'est-ce que vous attendez? Remplissez ce formulaire<span role="img" aria-labelledby="point_down">👇</span> et nous vous enverrons toutes les informations sur la façon de participer. Les séances gratuites le mardi 8 et le jeudi 10 decembre 2020 à 5h pm.
            </p>
            <br />
            {
              sent
              ?
                <div className="form-sent center color-1">
                  <h4>Votre formulaire a été envoyé avec succès <span role="img" aria-labelledby="clap">👏</span>. Vous recevrez un e-mail sous peu. Merci.</h4>
                </div>
              :
              <Formik
              initialValues={{
                nom: '',
                prenom: '',
                email: '',
                phone: '',
                city: '',
                interest: ''
              }}
              validationSchema={BaccSchema}
              onSubmit={(values, actions) =>{
                sendForm(values)
                actions.resetForm()
                isSent()
              }}
            >
              {({isSubmitting, isValid, handleReset}) => (
                <Form className="contact-form" ref={ el => formRef = el}>
                  <Field
                    type='text'
                    name='nom'
                    placeholder="Votre nom ..."
                    component={Input}
                  />
                  <Field
                    type='text'
                    name='prenom'
                    placeholder="Votre prenom ..."
                    component={Input}
                  />
                  <Field
                    type='email'
                    name='email'
                    placeholder="Votre email ..."
                    component={Input}
                  />
                  <Field
                    type='phone'
                    name='phone'
                    placeholder="Votre numero de telephone ..."
                    component={Input}
                  />
                  <Field
                    type='text'
                    name='city'
                    placeholder="Votre adresse (ville seulement) ..."
                    component={Input}
                  />
                  <Field
                    type='text'
                    name='interest'
                    placeholder="Ce que vous aimeriez qu'on voit dans le programme ..."
                    component={Textarea}
                  />

                  <br />
                  <br />
                  <div className="center">
                    <button className="mybtn-border center color-2-bg color-1" type='submit'>Envoyer<span role="img" aria-labelledby="handshake" >🤝</span></button>
                  </div>
                  
                </Form>           
              )}
            </Formik>}
          </div>
        </section>
      </div>
    </Layout> 
  )
}

export default Terminal
